import { ActionReducerMap } from '@ngrx/store';
import { TemporaryKeyActivatorActions, TemporaryKeyActivatorActionTypes } from './temporary-key-activator.actions';
import { temporaryKeyActivatorInitialState, TemporaryKeyActivatorState } from './temporary-key-activator.state';

function reducer(state = temporaryKeyActivatorInitialState, action: TemporaryKeyActivatorActions): TemporaryKeyActivatorState {
    switch (action.type) {
        case TemporaryKeyActivatorActionTypes.GetTemporaryKeyInfo: {
            return {
                ...state,
                status: 'loading',
                error: null,
            };
        }

        case TemporaryKeyActivatorActionTypes.GetTemporaryKeyInfoSuccess: {
            return {
                ...state,
                status: null,
                temporaryKeyResponse: action.response
            };
        }

        case TemporaryKeyActivatorActionTypes.GetTemporaryKeyInfoFailure: {
            return {
                ...state,
                status: 'failure',
                error: action.error,
                fatalError: action.fatal
            };
        }

        case TemporaryKeyActivatorActionTypes.ActivateTemporaryKey: {
            return {
                ...state,
                status: 'opening',
                error: null,
            };
        }

        case TemporaryKeyActivatorActionTypes.ActivateTemporaryKeySuccess: {
            state.temporaryKeyResponse.activationsCount--;

            return {
                ...state,
                status: 'opened',
            };
        }

        case TemporaryKeyActivatorActionTypes.ActivateTemporaryKeyFailure: {
            return {
                ...state,
                status: 'failure',
                error: action.error,
                fatalError: action.fatal
            };
        }

        case TemporaryKeyActivatorActionTypes.ResetTemporaryKeyState: {
            return {
                ...state,
                status: null,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export const temporaryKeyActivatorReducers: ActionReducerMap<{ temporaryKeys: TemporaryKeyActivatorState }> = {
    temporaryKeys: reducer
};
