export enum ResolutionBreakpoint {
    XSM_W_DOWN = '(max-width: 350px)',
    SM_W_DOWN = '(max-width: 575px)',
    MD_W_DOWN = '(max-width: 767px)',
    LG_W_DOWN = '(max-width: 991px)',
    XL_W_DOWN = '(max-width: 1199px)',

    XSM_W_UP = '(min-width: 301px)',
    SM_W_UP = '(min-width: 576px)',
    MD_W_UP = '(min-width: 768px)',
    LG_W_UP = '(min-width: 992px)',
    XL_W_UP = '(min-width: 1200px)',

    SM_H_UP = '(min-width: 701px)',
    SM_H_DOWN = '(max-width: 700px)'
}
