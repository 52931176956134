import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const materialModules = [
    MatSnackBarModule
];

@NgModule({
    imports: materialModules,
    exports: materialModules,
})
export class MaterialModule { }
