import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafePipeModule } from 'app/shared';
import { SpinnerLoaderModule } from '../spinner-loader';
import { CircleOpenButtonComponent } from './circle-open-button.component';

@NgModule({
    imports: [
        CommonModule,
        SafePipeModule,
        SpinnerLoaderModule
    ],
    declarations: [
        CircleOpenButtonComponent
    ],
    exports: [
        CircleOpenButtonComponent
    ]
})
export class CircleOpenButtonModule { }
