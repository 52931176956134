import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarActionType } from './snackbar-action.type';
import { SnackbarData } from './snackbar-data.model';
import { SnackbarComponent } from './snackbar.component';
import { SnackbarUtils } from './snackbar.utils';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    private config: MatSnackBarConfig<SnackbarData>;
    private snackbarRef: MatSnackBarRef<SnackbarComponent>;

    constructor(
        private snackBar: MatSnackBar
    ) {
        this.config = new MatSnackBarConfig();
        this.config.verticalPosition = SnackbarUtils.defaultVerticalPosition;
        this.config.horizontalPosition = SnackbarUtils.defaultHorizontalPosition;
        this.config.duration = 0;
    }

    showMessage(message: string, action?: SnackbarActionType): void {
        if (this.snackbarRef && this.config.data.action === 'error') {
            return;
        }

        this.config.duration = SnackbarUtils.prepareDuration(action);
        this.config.panelClass = SnackbarUtils.preparePanelClass(action);

        this.config.data = {
            message,
            action: action ?? 'error',
            dismiss: () => {
                this.snackbarRef.dismiss();
                this.snackbarRef = null;
            }
        };

        this.snackbarRef = this.snackBar.openFromComponent(SnackbarComponent, this.config);
    }

    showAction(
        message: string,
        action: SnackbarActionType,
        buttonLabel: string,
        cb: (success: boolean) => void
    ): void {
        this.config.duration = 0;
        this.config.panelClass = SnackbarUtils.preparePanelClass(action);

        this.config.data = {
            message,
            action: action ?? 'error',
            buttonLabel,
            dismiss: () => cb(true)
        };

        this.snackBar.openFromComponent(SnackbarComponent, this.config);
    }
}
