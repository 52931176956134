import { Pipe, PipeTransform } from '@angular/core';
import { CircleOpenButtonStateType } from 'app/ui/circle-open-button';
import { TemporaryKeyActivatorPendingType } from './temporary-key-activator-pending.type';

@Pipe({
    name: 'temporary_key_activator_circle_button'
})
export class TemporaryKeysCircleButtonPipe implements PipeTransform {
    transform(value: TemporaryKeyActivatorPendingType): CircleOpenButtonStateType {
        switch (value) {
            case 'opening': return 'pending';
            case 'opened': return 'success';
            case 'failure': return 'failure';
            default: return null;
        }
    }
}
