export enum ResourcePathEnum {
    RD_HORIZONTAL_LOGO = 'assets/rd-logo/rd-horizontal-logo',
    RD_PADLOCK = 'assets/images/rd-padlock',
    RD_APP_STORE = 'assets/images/rd-app-store',
    RD_GOOGLE_PLAY = 'assets/images/rd-google-play',
    RD_APP_GALLERY = 'assets/images/rd-app-gallery',
    RD_SUCCESS = 'assets/images/rd-success',
    RD_ERROR = 'assets/images/rd-error',
}

export enum ResourcePathEnumQuemChegou {
  RD_HORIZONTAL_LOGO = 'assets/rd-logo/quem_chegou/rd-horizontal-logo',
  RD_APP_STORE = 'assets/images/quem_chegou/rd-app-store',
  RD_GOOGLE_PLAY = 'assets/images/quem_chegou/rd-google-play',
  RD_APP_GALLERY = 'assets/images/quem_chegou/rd-app-gallery',
}
