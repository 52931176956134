import {ResourcePathEnum, ResourcePathEnumQuemChegou} from 'app/shared/models';
import {StoreLinkButtonEnum} from './store-link-button.enum';
import {StoreLinkButtonType} from './store-link-button.type';
import {environment} from 'environments/environment';

export class StoreLinkButtonUtil {
  static readonly mobileAppLink: string = 'https://rosdomofon.page.link/LZFP';

  private static readonly resourcesPathEnum = environment.quem_chegou ? ResourcePathEnumQuemChegou : ResourcePathEnum;

  private static readonly marketOptions: Record<StoreLinkButtonType, {
    imageLink: string,
    altName: string,
    marketUrl: string
  }> = {
    [StoreLinkButtonEnum.APP_STORE]:
      {
        imageLink: StoreLinkButtonUtil.resourcesPathEnum.RD_APP_STORE,
        altName: 'App Store image',
        marketUrl: environment.app.apple
      },
    [StoreLinkButtonEnum.GOOGLE_PLAY]:
      {
        imageLink: StoreLinkButtonUtil.resourcesPathEnum.RD_GOOGLE_PLAY,
        altName: 'Google Play image',
        marketUrl: environment.app.google
      },
    [StoreLinkButtonEnum.APP_GALLERY]:
      {
        imageLink: StoreLinkButtonUtil.resourcesPathEnum.RD_APP_GALLERY,
        altName: 'Huawei App Gallery image',
        marketUrl: environment.app.huawei
      }
  };

  static getImageLink(type: StoreLinkButtonType): string {
    if (!this.correctMarketType(type)) {
      return null;
    }

    return this.marketOptions[type].imageLink;
  }

  static getAltName(type: StoreLinkButtonType): string {
    if (!this.correctMarketType(type)) {
      return null;
    }

    return this.marketOptions[type].altName;
  }

  static getMarketUrl(type: StoreLinkButtonType): string {
    if (!this.correctMarketType(type)) {
      return null;
    }

    return this.marketOptions[type].marketUrl;
  }

  private static correctMarketType(type: StoreLinkButtonType): boolean {
    if (!this.marketOptions[type]) {
      console.warn(`Unknown market "${type}"`);
      return false;
    }

    return true;
  }
}
