import {HttpErrorResponse} from '@angular/common/http';
import {RdApiErrorCodes} from './rd-api-error-codes.enum';
import {RdApiErrorRecord} from './rd-api-error-record.model';
import {RdApiErrorResponse} from './rd-api-error-response.model';
import {RdApiErrorTranslatableTextEnum} from './rd-api-error-translatable-text.enum';
import {translatableTextRecords} from './rd-api-error-translatable-text.records';

export class RdApiErrorParserUtil {
    private static defaultError = 'api.default.error';
    private static defaultInternetConnectionError = 'api.default.internet_connection_error';

    static parseError(httpErrorResponse: HttpErrorResponse): RdApiErrorResponse {
        const rdApiErrorResponse: RdApiErrorResponse = httpErrorResponse.error;
        rdApiErrorResponse.status = httpErrorResponse.status;

        let consoleMessage = `Error! Status: ${httpErrorResponse.status.toString()}; Status text: ${httpErrorResponse.statusText}; Message: {0}`;
        let errorIdentifier: string;

        // If correct error response exists
        if (rdApiErrorResponse?.error) {
            let errorRecord: RdApiErrorRecord;

            // If error returned with code
            if (this.hasKnownErrorCode(rdApiErrorResponse)) {
                errorRecord = this.getRdaApiErrorRecord(RdApiErrorCodes[rdApiErrorResponse.errorCode]);
                errorIdentifier = rdApiErrorResponse.errorCode;
            } else {
                // Trying to parse error using error text
                errorRecord = this.getRdaApiErrorRecordUsingText(rdApiErrorResponse.error);
                errorIdentifier = rdApiErrorResponse.error;
                rdApiErrorResponse.errorMessageParams = [];
            }

            if (errorRecord) {
                rdApiErrorResponse.parsedError = this.prepareLocalizedMessage(errorRecord.text, rdApiErrorResponse.errorMessageParams);
                rdApiErrorResponse.fatal = errorRecord.fatal;
            }
        } else if (rdApiErrorResponse instanceof ProgressEvent && rdApiErrorResponse.total === 0 && rdApiErrorResponse.type === 'error') {
            // Internet connection broken
            rdApiErrorResponse.parsedError = this.defaultInternetConnectionError;
            errorIdentifier = rdApiErrorResponse.parsedError;
        } else {
            errorIdentifier = httpErrorResponse.message;
        }

        consoleMessage = consoleMessage.replace('{0}', errorIdentifier);
        console.error(consoleMessage);

        // If error translation endeed with fail
        if (!rdApiErrorResponse.parsedError) {
            rdApiErrorResponse.parsedError = this.defaultError;
        }

        return rdApiErrorResponse;
    }

    private static prepareLocalizedMessage(message: string, messageParams: string[]): string {
        messageParams.forEach((param: string, index: number) =>
            message = message.replace(`{${index}}`, param)
        );

        return message;
    }

    private static hasKnownErrorCode(errorResponse: RdApiErrorResponse): boolean {
        return errorResponse?.errorCode && RdApiErrorCodes[errorResponse?.errorCode];
    }

    private static getRdaApiErrorRecordUsingText(error: string): RdApiErrorRecord {
        const translatableTextKeys: string[] = Object.keys(RdApiErrorTranslatableTextEnum);

        for (const translatableTextKey of translatableTextKeys) {
            if (error.indexOf(RdApiErrorTranslatableTextEnum[translatableTextKey]) !== -1) {
                return this.getRdaApiErrorRecord(RdApiErrorTranslatableTextEnum[translatableTextKey]);
            }
        }

        return null;
    }

    private static getRdaApiErrorRecord(rdaApiRecordKey: RdApiErrorTranslatableTextEnum | RdApiErrorCodes): RdApiErrorRecord {
        return translatableTextRecords[rdaApiRecordKey];
    }
}
