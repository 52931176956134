import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'my-rd-temporary-keys-activate',
  templateUrl: './temporary-keys-activate.component.html',
  styleUrls: ['./temporary-keys-activate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemporaryKeysActivateComponent implements OnInit {
  verificationToken: string;

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.activatedRoute.snapshot.data.title);
    this.verificationToken = this.activatedRoute.snapshot.queryParams?.token;
  }
}
