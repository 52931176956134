import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RdApiErrorResponse } from 'app/shared/entities/rd/base';
import { RdTemporaryKeyResponse, RdTemporaryKeysApiService } from 'app/shared/entities/rd/rd-temporary-keys';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    ActivateTemporaryKey,
    ActivateTemporaryKeyFailure,
    ActivateTemporaryKeySuccess,
    GetTemporaryKeyInfo,
    GetTemporaryKeyInfoFailure,
    GetTemporaryKeyInfoSuccess,
    TemporaryKeyActivatorActionTypes
} from './temporary-key-activator.actions';


@Injectable()
export class TemporaryKeyActivatorEffects {
    constructor(
        private actions$: Actions,
        private temporaryKeysApiService: RdTemporaryKeysApiService,
    ) { }

    GetTemporaryKeyInfo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType<GetTemporaryKeyInfo>(TemporaryKeyActivatorActionTypes.GetTemporaryKeyInfo),
            switchMap(action =>
                this.temporaryKeysApiService.getTemporaryKeyInfo$(action.verificationToken)
                    .pipe(
                        map((response: RdTemporaryKeyResponse) =>
                            new GetTemporaryKeyInfoSuccess(response)
                        ),
                        catchError((error: RdApiErrorResponse) =>
                            of(new GetTemporaryKeyInfoFailure(error.parsedError, error.fatal))
                        )
                    )
            )
        );
    });

    ActivateTemporaryKey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType<ActivateTemporaryKey>(TemporaryKeyActivatorActionTypes.ActivateTemporaryKey),
            switchMap(action =>
                this.temporaryKeysApiService.activateTemporaryKey$(action.verificationToken)
                    .pipe(
                        map(() =>
                            new ActivateTemporaryKeySuccess()
                        ),
                        catchError((error: RdApiErrorResponse) =>
                            of(new ActivateTemporaryKeyFailure(error.parsedError, error.fatal))
                        )
                    )
            )
        );
    });
}
