<div class="anonimous-minimalistic-layout">
  <div class="anonimous-minimalistic-layout-header">
    <img
      alt="my-rd-logo"
      [src]="resourcesPathEnum.RD_HORIZONTAL_LOGO + '.png' | safe: 'url'"
      [srcset]="resourcesPathEnum.RD_HORIZONTAL_LOGO + '@2x.png' | safe: 'url'"
      class="anonimous-minimalistic-layout-header__logo"
    >
  </div>

  <div class="anonimous-minimalistic-layout-content">
    <ng-content></ng-content>
  </div>

  <div class="anonimous-minimalistic-layout-footer">
    <button
      my-rd-store-link-button
      [my-rd-market-type]="storeLinkButtonEnum.APP_STORE"
      [size]="(xsmWDownBreakpoint$ | async) ? 's' : 'm'"
      *ngIf="showAppStoreButton()"
    ></button>

    <button
      my-rd-store-link-button
      [my-rd-market-type]="storeLinkButtonEnum.GOOGLE_PLAY"
      [size]="(xsmWDownBreakpoint$ | async) ? 's' : 'm'"
      *ngIf="showGooglePlayButton()"
    ></button>

    <button
      my-rd-store-link-button
      [my-rd-market-type]="storeLinkButtonEnum.APP_GALLERY"
      [size]="(xsmWDownBreakpoint$ | async) ? 's' : 'm'"
      *ngIf="showGooglePlayButton()"
    ></button>
  </div>
</div>