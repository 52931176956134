<div
    class="spinner-loader"
    [attr.my-rd-size]="size"
>
    <svg
        class="spinner-loader-svg"
        focusable="false"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            class="spinner-loader-svg__circle"
            cx="50"
            cy="50"
            r="50"
            stroke-dasharray="314"
        ></circle>
    </svg>

    <div
        *ngIf="hasText"
        class="spinner-loader-svg__text"
    >
        <ng-content></ng-content>
    </div>
</div>
