import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RdApiService } from 'app/shared/entities/rd/base';
import { constants } from 'environments/consants';
import { Observable } from 'rxjs';
import { RdTemporaryKeyResponse } from './rd-temporary-key-response.model';

@Injectable({
    providedIn: 'root'
})
export class RdTemporaryKeysApiService extends RdApiService {
    constructor(httpClient: HttpClient) {
        super(httpClient, constants.RD_API.RDAS_SERVICE, ['v1']);
    }

    getTemporaryKeyInfo$(verificationToken: string): Observable<RdTemporaryKeyResponse> {
        const params: HttpParams = new HttpParams({
            fromObject: { verificationToken }
        });

        return this.get<RdTemporaryKeyResponse>('/temporary_keys', 1, { params });
    }

    activateTemporaryKey$(verificationToken: string): Observable<{}> {
        return this.post<null, {}>(`/temporary_keys/${verificationToken}/activate`, 1);
    }
}
