import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackbarActionType } from './snackbar-action.type';

export class SnackbarUtils {
    static readonly defaultDuration = 4000;
    static readonly defaultHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
    static readonly defaultVerticalPosition: MatSnackBarVerticalPosition = 'top';

    static preparePanelClass(action: SnackbarActionType): string[] {
        switch (action) {
            case 'success':
                return ['js-snackbar-custom-container', 'js-snackbar-custom-container--success'];
                break;
            case 'warning':
                return ['js-snackbar-custom-container', 'js-snackbar-custom-container--warning'];
                break;
            default:
                return ['js-snackbar-custom-container', 'js-snackbar-custom-container--error'];
        }
    }

    static prepareDuration(action: SnackbarActionType): number {
        if (action === 'success') {
            return this.defaultDuration;
        }

        return 0;
    }
}
