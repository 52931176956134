import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TemporaryKeyActivatorModuleState, TemporaryKeyActivatorRootState, TemporaryKeyActivatorState } from './temporary-key-activator.state';

const temporaryKeyActivatorFeatureSelector =
    createFeatureSelector<TemporaryKeyActivatorRootState, TemporaryKeyActivatorModuleState>('temporary-key-activator');

const selectState = createSelector(
    temporaryKeyActivatorFeatureSelector,
    (state: TemporaryKeyActivatorModuleState) => state.temporaryKeys
);

const selectTemporaryKey = createSelector(
    selectState,
    (state: TemporaryKeyActivatorState) => state.temporaryKeyResponse
);

const selectError = createSelector(
    selectState,
    (state: TemporaryKeyActivatorState) => state.error
);

const selectStatus = createSelector(
    selectState,
    (state: TemporaryKeyActivatorState) => state.status
);

const selectFatalError = createSelector(
    selectState,
    (state: TemporaryKeyActivatorState) => state.fatalError
);

export const temporaryKeyActivatorSelector = {
    selectState,
    selectTemporaryKey,
    selectError,
    selectStatus,
    selectFatalError
};
