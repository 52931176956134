import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, CoreModule } from './core';
import { SharedModule } from './shared';
import { CustomStoreModule } from './store/custom-store.module';
import { TemporaryKeysActivateModule } from './views/temporary-keys-activate/temporary-keys-activate.module';
import { NgxTranslateModule } from './translate/translate.module';

@NgModule({
  imports: [
    // CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,

    // Routing module
    AppRoutingModule,

    // Application modules
    SharedModule,
    CoreModule,
    CustomStoreModule,
    TemporaryKeysActivateModule,
    NgxTranslateModule,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
