import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RdTemporaryKeyResponse } from 'app/shared/entities/rd/rd-temporary-keys';
import { DestroyService } from 'app/shared/services';
import { State } from 'app/store/custom-store.reducer';
import { Observable, Subscriber } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { TemporaryKeyActivatorPendingType } from '../temporary-key-activator-pending.type';
import { ActivateTemporaryKey, GetTemporaryKeyInfo, GetTemporaryKeyInfoFailure, ResetTemporaryKeyState } from './temporary-key-activator.actions';
import { temporaryKeyActivatorSelector } from './temporary-key-activator.selectors';

@Injectable()
export class TemporaryKeyActivatorFacade {
    error$: Observable<string> = this.store.select(temporaryKeyActivatorSelector.selectError);
    status$: Observable<TemporaryKeyActivatorPendingType> = this.store.select(temporaryKeyActivatorSelector.selectStatus);
    fatalError$: Observable<boolean> = this.store.select(temporaryKeyActivatorSelector.selectFatalError);

    private temporaryKey$: Observable<RdTemporaryKeyResponse> = this.store.select(temporaryKeyActivatorSelector.selectTemporaryKey);

    constructor(
        private store: Store<State>,
        private destroy: DestroyService
    ) { }

    getTemporaryKeyInfo(verificationToken: string): void {
        this.store.dispatch(new GetTemporaryKeyInfo(verificationToken));
    }

    getTemporaryKeyInfoFailure(error: string, fatal?: boolean): void {
        this.store.dispatch(new GetTemporaryKeyInfoFailure(error, fatal));
    }

    activateTemporaryKey(verificationToken: string): void {
        this.store.dispatch(new ActivateTemporaryKey(verificationToken));
    }

    resetTemporaryKeyStateWithDelay(delayTime: number = 3000): void {
        new Observable((sub: Subscriber<void>) => sub.next())
            .pipe(delay(delayTime), takeUntil(this.destroy))
            .subscribe(() => {
                this.store.dispatch(new ResetTemporaryKeyState());
            });
    }

    addErrorListener(cb: (error: string) => void): void {
        this.error$
            .pipe(takeUntil(this.destroy))
            .subscribe((error: string) => error && cb(error));
    }

    addStatusListener(statusForListening: TemporaryKeyActivatorPendingType, cb: () => void): void {
        this.status$
            .pipe(takeUntil(this.destroy))
            .subscribe((status: TemporaryKeyActivatorPendingType) =>
                status === statusForListening && cb()
            );
    }


    addKeyInfoLoadedListener(cb: (temporarykey: RdTemporaryKeyResponse) => void): void {
        this.temporaryKey$
            .pipe(takeUntil(this.destroy))
            .subscribe((temporaryKey: RdTemporaryKeyResponse) => temporaryKey && cb(temporaryKey));
    }
}
