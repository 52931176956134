import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { reducers } from './custom-store.reducer';

@NgModule({
    imports: [
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            name: 'MyRD Store DevTools',
            logOnly: environment.production,
        }),
        StoreDevtoolsModule
    ],
    exports: [
        StoreModule,
        EffectsModule,
        StoreDevtoolsModule
    ]
})
export class CustomStoreModule { }
