import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResourcePathEnum, ResourcePathEnumQuemChegou } from 'app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from 'app/shared/services';
import { StoreLinkButtonEnum } from 'app/ui';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
  selector: 'my-rd-anonymous-minimalistic-layout',
  templateUrl: './anonymous-minimalistic-layout.component.html',
  styleUrls: ['./anonymous-minimalistic-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnonymousMinimalisticLayoutComponent {
  readonly storeLinkButtonEnum = StoreLinkButtonEnum;
  readonly resourcesPathEnum = environment.quem_chegou ? ResourcePathEnumQuemChegou : ResourcePathEnum;

  xsmWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.XSM_W_DOWN);

  constructor(
    public resolution: ResolutionService,
    private platform: Platform,
  ) { }

  showAppStoreButton(): boolean {
    return this.platform.ANDROID ? this.platform.IOS : this.platform.isBrowser;
  }

  showGooglePlayButton(): boolean {
    return this.platform.IOS ? this.platform.ANDROID : this.platform.isBrowser;
  }
}
