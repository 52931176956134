import { Component, HostBinding, Input } from '@angular/core';
import { ComponentSize } from 'app/shared/models';

@Component({
  selector: 'my-rd-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss'],
})
export class SpinnerLoaderComponent {
  // tslint:disable-next-line: no-input-rename
  @Input('hasText')
  @HostBinding('attr.has-text')
  hasText = false;

  // tslint:disable-next-line: no-input-rename
  @Input('size')
  @HostBinding('attr.size')
  size: ComponentSize = 'l';
}
