import { RdTemporaryKeyResponse } from 'app/shared/entities/rd/rd-temporary-keys';
import * as root from 'app/store/custom-store.reducer';
import { TemporaryKeyActivatorPendingType } from '../temporary-key-activator-pending.type';

export interface TemporaryKeyActivatorModuleState {
    temporaryKeys: TemporaryKeyActivatorState;
}

export interface TemporaryKeyActivatorRootState extends root.State {
    'temporary-key-activator': TemporaryKeyActivatorModuleState;
}

export interface TemporaryKeyActivatorState {
    temporaryKeyResponse: RdTemporaryKeyResponse;
    status: TemporaryKeyActivatorPendingType;
    error: string;
    fatalError: boolean;
}

export const temporaryKeyActivatorInitialState: TemporaryKeyActivatorState = {
    temporaryKeyResponse: null,
    status: null,
    error: null,
    fatalError: false,
};
