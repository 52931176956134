import { NgModule } from '@angular/core';
import { CircleOpenButtonModule } from './circle-open-button';
import { SpinnerLoaderModule } from './spinner-loader';
import { StoreLinkButtonModule } from './store-link-button';

@NgModule({
    imports: [
        CircleOpenButtonModule,
        StoreLinkButtonModule,
        SpinnerLoaderModule
    ],
    exports: [
        CircleOpenButtonModule,
        StoreLinkButtonModule,
        SpinnerLoaderModule
    ]
})
export class UiModule { }
