import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ResourcePathEnum } from 'app/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { CircleOpenButtonStateType } from './circle-open-button-state.enum';
import { CircleOpenButtonHelper } from './circle-open-button.helper';
import { CircleOpenButtonType } from './circle-open-button.type';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'button[my-rd-circle-open-button]',
  templateUrl: './circle-open-button.component.html',
  styleUrls: ['./circle-open-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircleOpenButtonComponent {
  readonly resourcesPathEnum = ResourcePathEnum;

  // tslint:disable-next-line: no-input-rename
  @Input('my-rd-button-type')
  @HostBinding('attr.my-rd-button-type')
  iconType: CircleOpenButtonType = 'padlock';

  // tslint:disable-next-line: no-input-rename
  @Input('disabled')
  @HostBinding('attr.disabled')
  disabled = false;

  @Input()
  set state(state: CircleOpenButtonStateType) {
    this.buttonState.next(state);
  }

  buttonState: BehaviorSubject<CircleOpenButtonStateType> = new BehaviorSubject('default');

  // tslint:disable-next-line: no-output-native
  @Output()
  private click: EventEmitter<void> = new EventEmitter();

  onClick(): void {
    this.click.emit();
  }

  get imageUrl(): string {
    return CircleOpenButtonHelper.getImageLink(this.iconType);
  }

  get buttonStateStream$(): Observable<CircleOpenButtonStateType> {
    return this.buttonState.asObservable();
  }
}
