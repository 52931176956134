import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafePipeModule } from 'app/shared';
import { StoreLinkButtonModule } from 'app/ui';
import { AnonymousMinimalisticLayoutComponent } from './anonymous-minimalistic-layout.component';

@NgModule({
    imports: [
        CommonModule,
        SafePipeModule,
        PlatformModule,
        StoreLinkButtonModule,
    ],
    declarations: [AnonymousMinimalisticLayoutComponent],
    exports: [AnonymousMinimalisticLayoutComponent],
})
export class AnonymousMinimalisticLayoutModule { }
