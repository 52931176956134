import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AnonymousMinimalisticLayoutModule } from 'app/components';
import { TemporaryKeyActivatorModule } from 'app/components/temporary-key-activator';
import { SharedModule } from 'app/shared';
import { TemporaryKeysActivateComponent } from './temporary-keys-activate.component';

@NgModule({
    imports: [
        SharedModule,
        TemporaryKeyActivatorModule,
        AnonymousMinimalisticLayoutModule
    ],
    declarations: [
        TemporaryKeysActivateComponent
    ]
})
export class TemporaryKeysActivateModule {
    constructor(@Optional() @SkipSelf() parentModule: TemporaryKeysActivateModule) {
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import TemporaryKeysModule module in the AppModule only.`);
        }
    }
}
