<div
  class="circle-open-button"
  (click)="disabled === false && onClick()"
>
  <div
    class="circle-open-button-container"
    [class.circle-open-button-container--disabled]="disabled"
  >
    <div class="circle-open-button-container-icon">
      
      <ng-container [ngSwitch]="buttonStateStream$ | async">
        <ng-container *ngSwitchDefault>
          <img
            [src]="imageUrl + '.png' | safe: 'url'"
            [srcset]="imageUrl + '@2x.png' | safe: 'url'"
            class="circle-open-button-container-icon__img"
          >
        </ng-container>

        <ng-container *ngSwitchCase="'failure'">
          <img
            [src]="resourcesPathEnum.RD_ERROR + '.png' | safe: 'url'"
            [srcset]="resourcesPathEnum.RD_ERROR + '@2x.png' | safe: 'url'"
            class="circle-open-button-container-icon__img"
          >
        </ng-container>

        <ng-container *ngSwitchCase="'success'">
          <img
            [src]="resourcesPathEnum.RD_SUCCESS + '.png' | safe: 'url'"
            [srcset]="resourcesPathEnum.RD_SUCCESS + '@2x.png' | safe: 'url'"
            class="circle-open-button-container-icon__img"
          >
        </ng-container>

        <ng-container *ngSwitchCase="'pending'">
          <my-rd-spinner-loader></my-rd-spinner-loader>
        </ng-container>

      </ng-container>
    </div>

    <div
      class="circle-open-button-container-content"
      [class.circle-open-button-container-content--failure]="(buttonStateStream$ | async) === 'failure'"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>