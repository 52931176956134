import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemporaryKeysActivateComponent } from 'app/views';
import { environment } from 'environments/environment';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

const routes: Routes = [
  {
    path: 'temporary-keys',
    children: [
      {
        path: 'activate',
        component: TemporaryKeysActivateComponent,
        pathMatch: 'full',
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(
      routes,
      {
        enableTracing: !environment.production,
        preloadingStrategy: QuicklinkStrategy
      },
    )
  ]
})
export class AppRoutingModule {
  constructor(@Optional() @SkipSelf() parentModule: AppRoutingModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import AppRouting module in the AppModule only.`);
    }
  }
}
