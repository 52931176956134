import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MaterialModule } from 'app/shared';
import { DestroyService } from 'app/shared/services';
import { UiModule } from 'app/ui';
import { SnackbarModule } from '../snackbar';
import { TemporaryKeyActivatorEffects, TemporaryKeyActivatorFacade, temporaryKeyActivatorReducers } from './store';
import { TemporaryKeysCircleButtonPipe } from './temporary-key-activator-circle-button.pipe';
import { TemporaryKeyActivatorComponent } from './temporary-key-activator.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
	imports: [
		CommonModule,
		MaterialModule,
		SnackbarModule,
		UiModule,
		StoreModule.forFeature('temporary-key-activator', temporaryKeyActivatorReducers),
		EffectsModule.forFeature([TemporaryKeyActivatorEffects]),
		TranslateModule
	],
    providers: [
        TemporaryKeyActivatorFacade,
        DestroyService
    ],
    declarations: [
        TemporaryKeyActivatorComponent,
        TemporaryKeysCircleButtonPipe
    ],
    exports: [TemporaryKeyActivatorComponent]
})
export class TemporaryKeyActivatorModule { }
