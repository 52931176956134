import { Action } from '@ngrx/store';
import { RdTemporaryKeyResponse } from 'app/shared/entities/rd/rd-temporary-keys';

export enum TemporaryKeyActivatorActionTypes {
    GetTemporaryKeyInfo = '[TemporaryKeys] GetTemporaryKeyInfo',
    GetTemporaryKeyInfoSuccess = '[TemporaryKeys] GetTemporaryKeyInfo Success',
    GetTemporaryKeyInfoFailure = '[TemporaryKeys] GetTemporaryKeyInfo Failure',

    ActivateTemporaryKey = '[TemporaryKeys] ActivateTemporaryKey',
    ActivateTemporaryKeySuccess = '[TemporaryKeys] ActivateTemporaryKey Success',
    ActivateTemporaryKeyFailure = '[TemporaryKeys] ActivateTemporaryKey Failure',

    ResetTemporaryKeyState = '[TemporaryKeys] ResetTemporaryKeyState Failure',
}

export class GetTemporaryKeyInfo implements Action {
    readonly type = TemporaryKeyActivatorActionTypes.GetTemporaryKeyInfo;

    constructor(public verificationToken: string) { }
}

export class GetTemporaryKeyInfoSuccess implements Action {
    readonly type = TemporaryKeyActivatorActionTypes.GetTemporaryKeyInfoSuccess;

    constructor(public response: RdTemporaryKeyResponse) { }
}

export class GetTemporaryKeyInfoFailure implements Action {
    readonly type = TemporaryKeyActivatorActionTypes.GetTemporaryKeyInfoFailure;

    constructor(public error: string, public fatal: boolean) { }
}

export class ActivateTemporaryKey implements Action {
    readonly type = TemporaryKeyActivatorActionTypes.ActivateTemporaryKey;

    constructor(public verificationToken: string) { }
}

export class ActivateTemporaryKeySuccess implements Action {
    readonly type = TemporaryKeyActivatorActionTypes.ActivateTemporaryKeySuccess;

    constructor() { }
}

export class ActivateTemporaryKeyFailure implements Action {
    readonly type = TemporaryKeyActivatorActionTypes.ActivateTemporaryKeyFailure;

    constructor(public error: string, public fatal: boolean) { }
}

export class ResetTemporaryKeyState implements Action {
    readonly type = TemporaryKeyActivatorActionTypes.ResetTemporaryKeyState;

    constructor() { }
}

export type TemporaryKeyActivatorActions =
    | GetTemporaryKeyInfo
    | GetTemporaryKeyInfoSuccess
    | GetTemporaryKeyInfoFailure

    | ActivateTemporaryKey
    | ActivateTemporaryKeySuccess
    | ActivateTemporaryKeyFailure

    | ResetTemporaryKeyState;
