export const environment = {
  production: false,
  quem_chegou: false,
  BASE_URI: 'https://dev.rdba.rosdomofon.com',
  lang: 'ru',
  languages: ['ru'],
  locale: 'ru-RU',
  app: {
    apple: 'https://apps.apple.com/ru/app/id1422470505',
    google: 'https://play.google.com/store/apps/details?id=com.rosdomofon.rdua',
    huawei: 'https://appgallery.huawei.com/#/app/C103925489',
  }
};
