<div class="snackbar">
  <div class="snackbar-content">
    <span class="snackbar-content__message">{{ data.message | translate }}</span>
  </div>

  <div class="snackbar-action">
    <button
      color="primary-inverse"
      mat-raised-button
      (click)="onDismiss()"
      class="snackbar-action__button"
    >
      {{ data.buttonLabel || 'OK' }}
    </button>
  </div>
</div>
