import { ResourcePathEnum } from 'app/shared/models';
import { CircleOpenButtonType } from './circle-open-button.type';

export class CircleOpenButtonHelper {
    private static readonly buttonOptions: Record<CircleOpenButtonType, { imageLink: string }> = {
        ['padlock']: { imageLink: ResourcePathEnum.RD_PADLOCK }
    };

    static getImageLink(type: CircleOpenButtonType): string {
        if (!this.correctButtonType(type)) {
            return null;
        }

        return this.buttonOptions[type].imageLink;
    }

    private static correctButtonType(type: CircleOpenButtonType): boolean {
        if (!this.buttonOptions[type]) {
            console.warn(`Unknown button "${type}"`);
            return false;
        }

        return true;
    }
}
