import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
	imports: [
		CommonModule,
		MatSnackBarModule,
		MatButtonModule,
		TranslateModule
	],
    declarations: [SnackbarComponent],
    exports: [SnackbarComponent],
})
export class SnackbarModule { }
