import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RdApiErrorParserUtil } from './rd-api-error-parser.util';
import { RdApiErrorResponse } from './rd-api-error-response.model';
import { RdApiRequestOptions } from './rd-api-request-options.model';

export abstract class RdApiService {
    constructor(
        private http: HttpClient,
        private endpointRoot: string,
        private apiVersions: string[]
    ) { }

    protected get<R>(
        path: string,
        apiVersion: number,
        options?: Partial<RdApiRequestOptions>
    ): Observable<R> {
        const requestUrl = this.prepareRequestUrl(apiVersion, path);
        return this.http
            .get<R>(requestUrl, { ...options })
            .pipe(catchError(this.handleError));
    }

    protected delete<R>(
        path: string,
        apiVersion: number,
        options?: Partial<RdApiRequestOptions>
    ): Observable<R> {
        const requestUrl = this.prepareRequestUrl(apiVersion, path);
        return this.http
            .delete<R>(requestUrl, { ...options })
            .pipe(catchError(this.handleError));
    }

    protected post<P, R>(
        path: string,
        apiVersion: number,
        payload?: Partial<P>,
        options?: Partial<RdApiRequestOptions>
    ): Observable<R> {
        const requestUrl = this.prepareRequestUrl(apiVersion, path);
        const requestBody = typeof payload === 'string' ? payload : JSON.stringify(payload);

        return this.http
            .post<R>(requestUrl, requestBody, { ...options })
            .pipe(catchError(this.handleError));
    }

    protected patch<P, R>(
        path: string,
        apiVersion: number,
        payload: Partial<P>,
        options?: Partial<RdApiRequestOptions>
    ): Observable<R> {
        const requestUrl = this.prepareRequestUrl(apiVersion, path);
        const requestBody = JSON.stringify(payload);

        return this.http
            .patch<R>(requestUrl, requestBody, { ...options })
            .pipe(catchError(this.handleError));
    }

    protected put<R>(
        path: string,
        apiVersion: number,
        payload?: string,
        options?: Partial<RdApiRequestOptions>
    ): Observable<R> {
        const requestUrl = this.prepareRequestUrl(apiVersion, path);
        const requestBody = typeof payload === 'string' ? payload : JSON.stringify(payload);

        return this.http
            .put<R>(requestUrl, requestBody, { ...options })
            .pipe(catchError(this.handleError));
    }

    protected checkFatalRdApiErrorResponse = (rdApiErrorResponse: RdApiErrorResponse) => {
        rdApiErrorResponse.fatal = false;
        return throwError(rdApiErrorResponse);
    }

    private prepareRequestUrl(apiVersion: number, path: string): string {
        const apiVersionStr = this.apiVersions?.[apiVersion - 1] ?? '';
        const requestUrl = `${this.endpointRoot}${apiVersionStr}${path}`;

        return requestUrl;
    }

    private handleError = (httpErrorResponse: HttpErrorResponse) => {
        const rdApiErrorResponse: RdApiErrorResponse = RdApiErrorParserUtil.parseError(httpErrorResponse);
        return throwError(rdApiErrorResponse);
    }
}
