import {TranslateService} from "@ngx-translate/core";

export class TemporaryKeyActivatorUtils {
    static getRemainingTimeFromExpirationDate(
      expirationDate: number,
      translateService: TranslateService
    ): string {
        const nowDate: number = new Date().getTime();

        const datePattern = translateService.instant('temporary.key.activator.date.format');

        if (!this.expirationDateValid(expirationDate)) {
            return datePattern.replace('{0}', '00').replace('{1}', '00');
        }

        const durationDate: number = expirationDate - nowDate;
        const minutes: number = Math.floor((durationDate / (1000 * 60)) % 60);
        const hours: number = Math.floor((durationDate / (1000 * 60 * 60)));

        const hoursStr: string = ((hours < 10) ? '0' + hours : hours).toString();
        const minutesStr: string = ((minutes < 10) ? '0' + minutes : minutes).toString();

        return datePattern.replace('{0}', hoursStr).replace('{1}', minutesStr);
    }

    /**
     * @param expirationDate - temporary key's expiration date in milliseconds
     * @param activationsCount - temporary key's activations count
     */
    private static expirationDateValid(expirationDate: number): boolean {
        const nowDate: number = new Date().getTime();

        if (nowDate > expirationDate) {
            return false;
        }

        return true;
    }
}
