<div class="temporary-key-activator">
  <ng-container *ngIf="(status$ | async) === 'loading'; else temporaryKeyBlock">
    <div class="temporary-key-activator-preload">
      <h4 class="temporary-key-activator-preload__title">
        {{ 'temporary.key.activator.message.loading' | translate }}
      </h4>
    </div>
  </ng-container>


  <ng-template #temporaryKeyBlock>
    <div class="temporary-key-activator-info">
      <ng-container *ngIf="(fatalError$ | async) !== true && temporaryKey">
        <h4 class="temporary-key-activator-info__title">
          {{ 'temporary.key.activator.message.you_have_been_provided_with_temporary_key_to_open' | translate }} «{{ temporaryKey.name }}»
        </h4>

        <p class="temporary-key-activator-info__address" *ngIf="temporaryKey.location">
          {{ 'temporary.key.activator.message.location' | translate }}: {{ temporaryKey.location.name }}
        </p>

        <div class="temporary-key-activator-info-expired">
          <p
            class="temporary-key-activator-info-expired__text"
            *ngIf="temporaryKey.expirationDate !== null"
          >
            {{ 'temporary.key.activator.message.key_works' | translate }}: {{ prepareRemainingDate(temporaryKey.expirationDate) }}
          </p>

          <p
            class="temporary-key-activator-info-expired__text"
            *ngIf="temporaryKey.activationsCount !== null"
          >
            {{ 'temporary.key.activator.message.remaining_openings' | translate }}: {{ temporaryKey.activationsCount }}
          </p>
        </div>
      </ng-container>
    </div>

    <button
      my-rd-circle-open-button
      class="temporary-key-activator__button"
      my-rd-button-type="padlock"
      [disabled]="(status$ | async) !== null || (fatalError$ | async) === true"
      [state]="
        (fatalError$ | async) === true ?
        'failure' :
        (status$ | async | temporary_key_activator_circle_button)
      "
      (click)="onActivateTemporaryKey()">
      <ng-container *ngIf="(fatalError$ | async) === true; else switchBlock">
        {{ 'temporary.key.activator.status.error' | translate }}
      </ng-container>
      <ng-template #switchBlock>
        <ng-container [ngSwitch]="status$ | async">
          <ng-container *ngSwitchCase="'opening'">
            {{ 'temporary.key.activator.status.opening' | translate }}
          </ng-container>

          <ng-container *ngSwitchCase="'opened'">
            {{ 'temporary.key.activator.status.opened' | translate }}
          </ng-container>

          <ng-container *ngSwitchCase="'failure'">
            {{ 'temporary.key.activator.status.error' | translate }}
          </ng-container>

          <ng-container *ngSwitchDefault>
            {{ 'temporary.key.activator.status.open' | translate }}
          </ng-container>
        </ng-container>
      </ng-template>

    </button>
  </ng-template>
</div>
