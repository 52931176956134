import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ComponentSize } from 'app/shared/models';
import { StoreLinkButtonEnum } from './store-link-button.enum';
import { StoreLinkButtonUtil } from './store-link-button.util';
import { StoreLinkButtonType } from './store-link-button.type';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'button[my-rd-store-link-button]',
  templateUrl: './store-link-button.component.html',
  styleUrls: ['./store-link-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreLinkButtonComponent {

  // tslint:disable-next-line: no-input-rename
  @Input('my-rd-market-type')
  @HostBinding('attr.my-rd-market-type')
  type: StoreLinkButtonType = StoreLinkButtonEnum.GOOGLE_PLAY;

  // tslint:disable-next-line: no-input-rename
  @Input('size')
  @HostBinding('attr.size')
  size: ComponentSize = 'm';

  constructor(
    private platform: Platform
  ) { }

  get imageUrl(): string {
    return StoreLinkButtonUtil.getImageLink(this.type);
  }

  get alt(): string {
    return StoreLinkButtonUtil.getAltName(this.type);
  }

  get marketUrl(): string {
    let marketUrl: string = StoreLinkButtonUtil.getMarketUrl(this.type);

    if (marketUrl !== null) {
      if (this.platform.ANDROID || this.platform.IOS) {
        marketUrl = StoreLinkButtonUtil.mobileAppLink;
      }
    }

    return marketUrl;
  }
}
