import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '../destroy.service';
import { ResolutionBreakpoint } from './resolution-breakpoint.enum';

@Injectable({
    providedIn: 'root'
})
export class ResolutionService {
    private activeBreakpoints: Record<string, BehaviorSubject<boolean>> = {};

    constructor(
        private breakpointObserver: BreakpointObserver,
        private destroy: DestroyService
    ) { }

    initResolutionChangeDetection(): void {
        const breakpoints: string[] = Object.values(ResolutionBreakpoint);

        this.breakpointObserver
            .observe(breakpoints)
            .pipe(takeUntil(this.destroy))
            .subscribe((observeResponse: BreakpointState) =>
                this.parseBreakpointsResponse(observeResponse.breakpoints)
            );
    }

    parseBreakpointsResponse(breakpoints: { [key: string]: boolean }): void {
        Object
            .keys(breakpoints)
            .forEach((key: string) => {
                if (this.activeBreakpoints[key]) {
                    this.activeBreakpoints[key].next(breakpoints[key]);
                } else {
                    this.activeBreakpoints[key] = new BehaviorSubject(breakpoints[key]);
                }
            });
    }

    getBreakpoint(breakpoint: ResolutionBreakpoint): Observable<boolean> {
        return this.activeBreakpoints[breakpoint].asObservable();
    }
}
