import {ChangeDetectionStrategy, Component, OnInit, Renderer2} from '@angular/core';
import { AppComponentHelper } from './app.component.helper';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'my-rd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppComponentHelper],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  constructor(
    private appComponentHelper: AppComponentHelper,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {
    this.translate.get([
      'app.title',
    ]).subscribe((res: string) => {
      this.renderer.setProperty(document.querySelector('title'), 'innerHTML', res['app.title']);
    });
  }

  ngOnInit(): void {
    this.appComponentHelper.addRelativeHeightCalculation();
    this.appComponentHelper.initResolutionChangeDetection();
  }
}
