import {RdApiErrorCodes} from './rd-api-error-codes.enum';
import {RdApiErrorRecord} from './rd-api-error-record.model';
import {RdApiErrorTranslatableTextEnum} from './rd-api-error-translatable-text.enum';

export const translatableTextRecords: Record<RdApiErrorTranslatableTextEnum | RdApiErrorCodes, RdApiErrorRecord> = {

  [RdApiErrorTranslatableTextEnum.TEMPORARY_KEY_ACTIVATIONS_EXCEEDED]: {
    text: 'temporary.key.error.activations_exceeded',
    fatal: true
  },
  [RdApiErrorTranslatableTextEnum.TEMPORARY_KEY_IS_EXPIRED]: {
    text: 'temporary.key.error.is_expired',
    fatal: true
  },

  [RdApiErrorTranslatableTextEnum.TEMPORARY_KEY_INCORRECT]: {
    text: 'temporary.key.error.incorrect',
    fatal: true
  }
};
