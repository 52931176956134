import { Injectable } from '@angular/core';
import { ResolutionService } from 'app/shared/services';

@Injectable()
export class AppComponentHelper {
    constructor(
        private resolution: ResolutionService
    ) { }

    addRelativeHeightCalculation(): void {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

        window.addEventListener('resize', () => {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        });
    }

    initResolutionChangeDetection(): void {
        this.resolution.initResolutionChangeDetection();
    }
}
