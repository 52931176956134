import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from 'app/shared';
import { AppComponent } from './components';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        AppComponent
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }
}
