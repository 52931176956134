import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafePipeModule } from 'app/shared';
import { StoreLinkButtonComponent } from './store-link-button.component';

@NgModule({
    imports: [
        CommonModule,
        PlatformModule,
        SafePipeModule
    ],
    declarations: [
        StoreLinkButtonComponent
    ],
    exports: [
        StoreLinkButtonComponent,
        PlatformModule
    ]
})
export class StoreLinkButtonModule { }
