import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SnackbarService } from 'app/components/snackbar';
import { RdApiErrorTranslatableTextEnum, translatableTextRecords } from 'app/shared/entities/rd/base';
import { RdTemporaryKeyResponse } from 'app/shared/entities/rd/rd-temporary-keys';
import { ResourcePathEnum } from 'app/shared/models';
import { StoreLinkButtonEnum } from 'app/ui/store-link-button';
import { Observable } from 'rxjs';
import { TemporaryKeyActivatorFacade } from './store';
import { TemporaryKeyActivatorPendingType } from './temporary-key-activator-pending.type';
import { TemporaryKeyActivatorUtils } from './temporary-key-activator.utils';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'my-rd-temporary-key-activator',
  templateUrl: './temporary-key-activator.component.html',
  styleUrls: ['./temporary-key-activator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemporaryKeyActivatorComponent {
  readonly storeLinkButtonEnum = StoreLinkButtonEnum;
  readonly resourcesPathEnum = ResourcePathEnum;
  readonly parseInt = Number.parseInt;

  @Input() set verificationToken(verificationToken: string) {
    this._verificationToken = verificationToken;

    this.temporaryKeysFacade.addErrorListener(
      (error: string) => {
        this.snackbarService.showMessage(error);
        this.temporaryKeysFacade.resetTemporaryKeyStateWithDelay();
      }
    );

    this.temporaryKeysFacade.addStatusListener(
      'opened',
      () => this.temporaryKeysFacade.resetTemporaryKeyStateWithDelay()
    );

    this.temporaryKeysFacade.addKeyInfoLoadedListener(
      (temporaryKey: RdTemporaryKeyResponse) => this.temporaryKey = temporaryKey
    );

    if (!this.verificationToken) {
      this.temporaryKeysFacade.getTemporaryKeyInfoFailure(
        this.translateService.instant(
          translatableTextRecords[RdApiErrorTranslatableTextEnum.TEMPORARY_KEY_INCORRECT].text
        ),
        true
      );
      return;
    }

    this.temporaryKeysFacade.getTemporaryKeyInfo(this.verificationToken);
  }

  temporaryKey: RdTemporaryKeyResponse;
  status$: Observable<TemporaryKeyActivatorPendingType> = this.temporaryKeysFacade.status$;
  fatalError$: Observable<boolean> = this.temporaryKeysFacade.fatalError$;

  // tslint:disable-next-line: variable-name
  private _verificationToken: string;

  constructor(
    private snackbarService: SnackbarService,
    private temporaryKeysFacade: TemporaryKeyActivatorFacade,
    private translateService: TranslateService
  ) { }

  // tslint:disable-next-line: adjacent-overload-signatures
  get verificationToken(): string {
    return this._verificationToken;
  }

  onActivateTemporaryKey(): void {
    this.temporaryKeysFacade.activateTemporaryKey(this.verificationToken);
  }

  prepareRemainingDate(expirationDate: number): string {
    return TemporaryKeyActivatorUtils.getRemainingTimeFromExpirationDate(expirationDate, this.translateService);
  }
}
